import React, { Component } from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";

import { Link } from "react-router-dom";

export default class NotFound extends Component {
  render() {
    return (
      <div className="content">
        <Header />

        <div className="espaciogris"></div>

        <div className="content">
          <div className="pasos pasofinal">
            <div className="card">
              <div className="barra"></div>

              <div className="centro confirmacion">
                <React.Fragment>
                  <div className="titulo">ERROR</div>
                  <div className="subtitulo">
                    Momentáneamente nuestra página no está disponible, inténtalo
                    más tarde. Gracias por tu comprensión
                  </div>

                  <div className="row">
                    <button className="boton">
                      <Link to="/">
                        <span className="ui-button-text ui-c ">Inicio</span>
                      </Link>
                    </button>
                  </div>
                </React.Fragment>
              </div>

              <div className="dudas">
                <div className="txt">
                  Si tienes dudas sobre el producto, contáctate con un asesor al{" "}
                  <span>600 660 1212 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
