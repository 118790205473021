import React from "react";
import Header from "../Home/Header";
import Footer from "../Home/Footer";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { Row, Col, Table, Container, Popover, OverlayTrigger } from "react-bootstrap";

const Resumen = (props) => {
  let state = {};
  if (props.location.state != null) { state = props.location.state; }
  else { props.history.push("/"); }

  let bancos = {
    1: "BANCO DE CHILE",
    14: "BANCO SCOTIABANK",
    16: "BANCO DE CRÉDITO E INVERSIONES",
    28: "BANCO BICE",
    31: "HSBC BANK USA",
    37: "BANCO SANTANDER CHILE",
    39: "BANCO ITAU CORPBANCA",
    49: "BANCO SECURITY",
    504: "SCOTIABANK AZUL (EX BANCO BBVA)",
    12: "BANCOESTADO",
    51: "BANCO FALABELLA",
    53: "BANCO RIPLEY",
    55: "BANCO CONSORCIO",
    9: "BANCO INTERNACIONAL",
    59: "BANCO BTG PACTUAL CHILE",
  };

  let cuentas = {
    1: "Cuenta Corriente",
    4: "Cuenta Vista",
    3: "Ahorro",
  };

        //dataLayer
        const data = {
          dataLayer: {
            event: 'RetiroExitoso',
            ecommerce: {
              purchase: {
                actionField: {
                  id: state.plan.ticketNumber + "-" + state.personal.rut,          // Transaction ID. Required for purchases and refunds.
                  affiliation: 'Ahorro',
                  revenue: state.retirar.saldo.clp.toFixed(0),   // Total transaction value (incl. tax and shipping)
                  tax: 0.00,
                  shipping: 0.00,
                },
                products: [{          // List of productFieldObjects.
                  name: state.plan.nombre,  // Name or ID is required.
                  id: state.plan.product,
                  price: state.retirar.saldo.clp.toFixed(0),
                  brand: 'Seguro',
                  category: state.retirar.solicitud,
                  variant: bancos[state.bancarios.banco],
                  quantity: 1,
                  position: state.retirar.porcentaje,
                }]
              }
            }
          },
          dataLayerName: 'ResumenDataLayer'
        };  
        window.dataLayer.push(data);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3"><strong>Información</strong></Popover.Title>
      <Popover.Content>
        Los datos que verás se encuentran desactualizados porque acabas de generar un retiro y debemos volver a calcular tus ahorros disponibles. Si cierras sesión, recién podrás volver a acceder mañana, cuando los saldos de tu seguro estén actualizados.
        <button className="info_btn" type="button">
          <Link
            to={{
              pathname: "/main",
              state: state.personal,
            }}
          >
            <span className="ui-button-text ui-c">
              Aceptar
                  </span>
          </Link>
        </button>
      </Popover.Content>
    </Popover>
  );

  const Message = () => (
    <OverlayTrigger trigger="click" placement="top" overlay={popover}>
      <button className="info_btn" type="button">
        <span className="ui-button-text ui-c">Volver a mis seguros</span>
      </button>
    </OverlayTrigger>
  );

  return (
    <React.Fragment>
      {props.location.state != null ? (
        <div className="content">
          {/* <div class="card mt-4"> */}
          <div className="card mt-4">
            <Header />
            {/* <div class="card-body"> */}
            <div className="card-body">
              <Container className="mt-5">
                <Row className="subtitle mb-3">
                  La solicitud de rescate ha sido ingresado con éxito.
            </Row>
                <Row className="ini_texto">
                  Se ha enviado un correo a {state.personal.email} con la
              información de tu requerimiento:
            </Row>
                <Row className="resume_texto_normal mt-3 mb-3 ml-1">
                  <ul>
                    <li>
                      <span className="font-weight-bold">Contratante : </span>{" "}
                  &nbsp;
                  {state.personal.nombre}
                    </li>
                    <li>
                      <span className="font-weight-bold">Seguro : </span> &nbsp;
                  {state.plan.nombre}
                    </li>
                    <li>
                      <span className="font-weight-bold">N de solicitud : </span>{" "}
                  &nbsp;{state.num_solicitud}
                    </li>
                  </ul>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>Datos del rescate </Row>
                    <Row className="mr-5">
                      <Table striped bordered hover className="resume_table mt-3">
                        <tbody>
                          <tr>
                            <td>Disponible para retiro</td>
                            <td>
                              <NumberFormat
                                value={state.plan.monto_disponible_uf}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                prefix={"UF "}
                                suffix={""}
                              />
                              {" "}
                              <NumberFormat
                                value={state.plan.monto_disponible_clp}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={0}
                                prefix={"($"}
                                suffix={"*)"}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Porcentaje a retirar</td>
                            <td>{state.retirar.porcentaje} %</td>
                          </tr>
                          <tr>
                            <td>Monto a retirar</td>
                            <td>
                              <NumberFormat
                                value={state.retirar.rescate.uf}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                prefix={"UF "}
                                suffix={""}
                              />
                              {" "}
                              <NumberFormat
                                value={state.retirar.rescate.clp}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={0}
                                prefix={"($"}
                                suffix={"*)"}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Saldo</td>
                            <td>
                              <NumberFormat
                                value={state.retirar.saldo.uf}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={2}
                                prefix={"UF "}
                                suffix={""}
                              />
                              {" "}
                              <NumberFormat
                                value={state.retirar.saldo.clp}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                decimalScale={0}
                                prefix={"($"}
                                suffix={"*)"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>

                    <Row className="resume_texto_normal">
                      <NumberFormat
                        value={state.uf}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"*Valor referencial calculado según el valor UF del día de hoy $"}
                        decimalScale={2}
                        className=""
                      />
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>Datos para abono</Row>
                    <Row className="mr-5">
                      <Table striped bordered hover className="resume_table mt-3">
                        <tbody>
                          <tr>
                            <td>Nombre del titular</td>
                            <td>{state.personal.nombre}</td>
                          </tr>
                          <tr>
                            <td>Rut titular</td>
                            <td>{state.personal.rut}</td>
                          </tr>
                          <tr>
                            <td>Banco</td>
                            <td>{bancos[state.bancarios.banco]}</td>
                          </tr>
                          <tr>
                            <td>Tipo de cuenta</td>
                            <td>{cuentas[state.bancarios.tipo_cuenta]}</td>
                          </tr>
                          <tr>
                            <td>Nr de cuenta</td>
                            <td>{state.bancarios.cuenta}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                  </Col>
                </Row>{" "}
                <Row className="mt-3 d-flex justify-content-center">
                  <p>El monto solicitado de tu fondo se encontrará disponible dentro de los próximos 10 días hábiles y se calculará con la UF del día de pago. 
                    Recuerda que el monto final puede estar sujeto al impuesto a la renta, de acuerdo a lo definido por el SII y, por lo tanto, podría ser menor al monto solicitado.</p>
                  <p>Si al momento de solicitar un movimiento en tu valor póliza tienes alguna <strong>retención ordenada por algún tribunal</strong>, tu solicitud podría quedar sin efecto</p>
                  <p>Si existe algún inconveniente con la cuenta indicada, el monto quedará disponible a través de un vale vista del Banco Estado dentro del mismo plazo.</p>
                </Row>
                <Row className="mt-3 d-flex justify-content-center">
                  {/* 
                <button className="info_btn" type="button">
                <Link
                  to={{
                    pathname: "/main",
                    state: state.personal,
                  }}
                >
                  <span className="ui-button-text ui-c ">
                    Volver a mis seguros
                  </span>
                </Link>
              </button>
              */}

                  <Message />

                </Row>
              </Container>
              <hr />
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Resumen;
