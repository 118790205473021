import http from "./http_common";

class MetlifeDataService {
  // get data by Login
  login(data) {
    return http.post("/login", data, {
      headers: {
        "Content-type": "application/json",
      },
    });
  }
  // get token
  getToken(data) {
    return http.post("/recaptcha/saving/", data, {
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  // get data by RUT
  getData(data, token) {
    return http.post("/saving/policy", data, {
      headers: {
        Authorization: token,
      },
    });
  }
  // request Code
  requestCode(token, data) {
    return http.post(data.uri, data, {
      headers: {
        Authorization: token,
      },
    });
  }
  // validate Code
  validateCode(token, data) {
    return http.post("/token", data, {
      headers: {
        Authorization: token,
      },
    });
  }
  // login by cedula
  loginByCedula(token, data) {
    return http.post("/loginByCedula", data, {
      headers: {
        Authorization: token,
      },
    });
  }
  // update personal data
  updatePersonal(token, data) {
    return http.post("/updatePersonal", data, {
      headers: {
        Authorization: token,
      },
    });
  }
  // get planes by rut
  getPlanes(token, data) {
    return http.post("/getPlanes", data, {
      headers: {
        Authorization: token,
      },
    });
  }
  // request a rescate
  requestRescate(token, data) {
    return http.post("/saving/rescue", data, {
      headers: {
        Authorization: token,
      },
    });
  }

  // request a policy history
  getHistoryPlans(token, data) {
    return http.post("/saving/rescue/history", data, {
      headers: {
        Authorization: token,
      },
    });
  }

  getUF() {
    return http.get("/currency/uf");
  }

  getSimulation(token, data) {
    return http.post("/saving/rescue/calculator", data, {
      headers: {
        Authorization: token,
      },
    });
  }

  validateRut(token, data) {
    return http.post("/saving/rut", data, {
      headers: {
        Authorization: token,
      },
    });
  }

  getCertificate(token, data) {
    return http.post("/saving/certificate", data, {
      headers: { Authorization: token },
      responseType: "blob",
    });
  }

  getCertPrev(token, data) {
    return http.post("/saving/prevrequests", data, {
      headers: {
        Authorization: token,
      },
      responseType: "blob",
    });
  }
}

export default new MetlifeDataService();
