import React, { Component } from "react";
import Header from "../Home/Header";
import BodyInicio from "./BodyInicio";
import Footer from "../Home/Footer";
// import loading from "../../assets/css/img/loading-orange.gif";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datos: null,
    };
    if (this.props.location.state != null) {
      this.state.datos = this.props.location.state;
    } else {
      this.props.history.push("/");
    }
  }

  logout() {
    this.props.location.state = null;
    this.props.history.push("/");
  }

  render() {
    return (
      /**
      <div className="content">
        <Header />
        <BodyInicio datos={this.state.datos} logout={this.logout.bind(this)} />

        <Footer />
      </div>
*/
      <div className="content">
        {/* <div class="card mt-4"> */}
        <div className="card mt-4">
          <Header />
          {/* <div class="card-body"> */}
          <div className="card-body">
            <BodyInicio datos={this.state.datos} logout={this.logout.bind(this)} />
            <hr />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
