//import React, { Component } from "react";
import React from "react";
//import logo from './logo.svg';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "../components/Home/Home";

import NotFound from "../components/Error/NotFound";

import DatosPersonales from "../components/ConfirmarDatos/DatosPersonales";
import DatosBancarios from "../components/ConfirmarDatos/DatosBancarios";
import DatosRescate from "../components/ConfirmarDatos/DatosRescate";
import ValidateCode from "../components/Home/ValidateCode";
import Main from "../components/Main/Main";
import Resumen from "../components/Main/Resumen";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/resumen" component={Resumen} />
        <Route exact path="/rescate" component={DatosRescate} />
        <Route exact path="/bancarios" component={DatosBancarios} />
        <Route exact path="/personales" component={DatosPersonales} />
        <Route exact path="/validateCode" component={ValidateCode} />
        <Route exact path="/main" component={Main} />
        <Route exact path="/" component={Home} />

        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;