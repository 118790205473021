import React, { Component } from "react";
import Header from "../Home/Header";
import BodyPersonales from "./BodyPersonales";
import Footer from "../Home/Footer";

class DatosPersonales extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (this.props.location.state != null) {
      this.state.datos = this.props.location.state;
    } else {
      this.props.history.push("/");
    }
  }
  logout() {
    this.props.location.state = null;
    this.props.history.push("/");
  }
  nextStep() {
    this.props.history.push({
      pathname: "/rescate",
      state: this.state.datos,
    });
  }

  render() {
    return (
      /**
      <div className="content">
        <Header />

        <BodyPersonales
          datos={this.state.datos}
          logout={this.logout.bind(this)}
          nextStep={this.nextStep.bind(this)}
        />

        <Footer />
      </div>
*/
      <div className="content">
        {/* <div class="card mt-4"> */}
        <div className="card mt-4">
          <Header />
          {/* <div class="card-body"> */}
          <div className="card-body">
            <BodyPersonales
              datos={this.state.datos}
              logout={this.logout.bind(this)}
              nextStep={this.nextStep.bind(this)}
            />
            <hr />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default DatosPersonales;
