import axios from "axios";

import { GEContext, genericError } from "../index";

const url = process.env.REACT_APP_API_URL;

const httpClient = axios.create({
  //appengine
  baseURL:
    url,
  headers: { "Content-type": "application/json", },
});

const erroresGenericos = [ 403, 503];

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if(erroresGenericos.indexOf(error.request.status) != -1) {
      genericError.setShow(true);
      return null;	
    }
    // Do something with request error
    return Promise.reject(error);
  }
);

export default httpClient;