import React from "react";
import metlifelogo from "../../assets/css/img/metlife.png";
import bancologo from "../../assets/css/img/bech_logo_2.png";
import { Image, Container, Row, Col } from "react-bootstrap";

const header = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col className="mt-2 mb-2">
          <Image src={bancologo} fluid />
        </Col>
        <Col xs={6} />
        <Col className="mt-2 mb-2">
          <Image src={metlifelogo} fluid />
        </Col>
      </Row>
    </Container>
  );
};

export default header;
