import React, { Component } from "react";
import Header from "./Header";
import Banner from "./Banner";
import BodyCode from "./BodyCode";
import Footer from "./Footer";
// import loading from "../../assets/css/img/loading-orange.gif";

class ValidateCode extends Component {
  constructor(props) {
    super(props);

    this.state = { datos: {}, };

    if (this.props.location.state != null) { this.state.datos = this.props.location.state; }
    else { this.props.history.push("/"); }
  }

  logout() {
    this.props.location.state = null;
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="content">
        <div className="card mt-4">
          <Header />
          <Banner />
          <div className="card-body">
            <BodyCode datos={this.state.datos}
              logout={this.logout.bind(this)} />
            <hr />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default ValidateCode;